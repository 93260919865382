// eslint-disable-next-line @typescript-eslint/no-unused-vars
import NxWelcome from './nx-welcome'

export function App() {
  console.log(import.meta.env.NX_IOS_APP_ID)
  console.log(import.meta.env.NX_ANDROID_PACKAGE_NAME)
  console.log(import.meta.env.NX_ANDROID_SHA_256)
  return (
    <>
      <NxWelcome title="app-doctor" />
      <div />

      {/* START: routes */}
      {/* These routes and navigation have been generated for you */}
      {/* Feel free to move and update them to fit your needs */}
      {/* <br />
      <hr />
      <br />
      <div role="navigation">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/page-2">Page 2</Link>
          </li>
        </ul>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              This is the generated root route.{' '}
              <Link to="/page-2">Click here for page 2.</Link>
            </div>
          }
        />
        <Route
          path="/page-2"
          element={
            <div>
              <Link to="/">Click here to go back to root page.</Link>
            </div>
          }
        />
      </Routes> */}
      {/* END: routes */}
    </>
  )
}

export default App
